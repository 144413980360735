/*
 * @Author: 智客云网络科技
 * @Date: 2021-10-16 16:46:06
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-27 09:51:09
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\Frame\Auth_New\api.js
 */

import http from "@/api";

/**************************** 权限操作 ****************************/

// 创建新权限/组
export const createPermission = (params) =>
  http.post("/v1/Auth/CreatePermission", params);

// 修改权限/组
export const updatePermission = (params) =>
  http.put("/v1/Auth/UpdatePermission", params);

// 删除权限/组
export const delPermission = (params) =>
  http.put("/v1/Auth/DelPermission", params);

// 获取权限/组信息
export const getPermissionsInfo = (params) =>
  http.put("/v1/Auth/GetPermissionsInfo", params);

// 获取权限/组表格
export const getPermissionsTable = (params) =>
  http.put("/v1/Auth/GetPermissionsTable", params);
