<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-24 15:59:11
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-27 10:13:44
 * @Description: 权限管理
 * @FilePath: \src\views\Frame\Auth_New\Permission\PermissionManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="success"
        icon="el-icon-plus"
        @click="openCreatePermissionModel()"
      >
        添加权限
      </el-button>
      <el-button
        size="small"
        type="warning"
        icon="el-icon-refresh"
        @click="loadData()"
      >
        重载数据
      </el-button>
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="mini"
      stripe
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      row-key="right_id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="80px"
      ></el-table-column>

      <!-- 权限/组名称 -->
      <el-table-column
        label="权限/组名称"
        prop="per_name"
        align="center"
        show-overflow-tooltip
        width="180px"
      ></el-table-column>

      <!-- 权限类型 -->
      <el-table-column label="权限类型" align="center" width="120px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.per_type === 'x103001'"
            >权限组</el-tag
          >
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.per_type == 'x103005'"
            >常规权限</el-tag
          >
          <el-tag
            size="small"
            effect="plain"
            type="warning"
            v-else-if="s.row.per_type == 'x103010'"
            >按钮权限</el-tag
          >
          <el-tag
            size="small"
            effect="plain"
            type="info"
            v-else-if="s.row.per_type == 'x103015'"
            >接口权限</el-tag
          >
          <el-tag size="small" effect="plain" type="danger" v-else>未知</el-tag>
        </template>
      </el-table-column>

      <!-- 资源标识 -->
      <el-table-column
        label="资源标识"
        prop="per_uri"
        align="center"
        show-overflow-tooltip
        width="180px"
      ></el-table-column>

      <!-- 权限状态 -->
      <el-table-column label="状态" align="center" width="80px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.status == 1"
            >启用</el-tag
          >
          <el-tag size="small" effect="plain" type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>

      <!-- 描述 -->
      <el-table-column label="描述">
        <template slot-scope="s"
          ><span>{{ s.row.per_notes }}</span></template
        >
      </el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="left" width="240px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            v-if="s.row.per_edit"
            @click="openUpdatePermissionModel(s.row.per_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            v-if="s.row.per_edit"
            @click="delPermission(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[10, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <!-- <nu-right ref="nu-right"></nu-right> -->
  </div>
</template>

<script>
import { getPermissionsTable, delPermission } from "../api"; //页面专有接口
// import NuRight from "./NURight";
export default {
  name: "PermissionManage", //组件名
  components: {
    // NuRight,
  },
  data() {
    return {
      p: {
        pageNo: 1, //当前页
        pageSize: 10, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], // 数据集合
    };
  },
  methods: {
    //载入数据
    loadData() {
      getPermissionsTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
      })
        .then((res) => {
          this.TableData = res.data.data; // 重写数据
          this.p.dataCount = res.data.dataCount; // 重写数据行数
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 103002 || err.code === 103003) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开权限创建组件
    openCreatePermissionModel() {
      this.$refs["nu-right"].open("create");
    },

    //打开权限修改组件
    openUpdataPermissionModel(per_id) {
      this.$refs["nu-right"].open("update", per_id);
    },

    /**
     * 删除权限
     */
    delPermission(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除权限/组【" +
          data.per_name +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delPermission({
            kr_id: data.right_id,
          })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm.loadData(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
